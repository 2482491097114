import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  SocialMedia,
  Content,
  CallToActionBanner,
  OutboundLink,
  PaginatedGrid,
  componentIterator,
  Image,
  Review,
  CustomerReviewModal,
} from "@bluefin/components";
import { Grid, Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      externalOrderOnlinePage,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"home-hero"}>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
                background={true}
                forceChildren={true}
              >
                <div className={"welcome-content-container"}>
                  <Grid className={"welcome-content"} stackable={true}>
                    <Grid.Row verticalAlign={"middle"}>
                      <Grid.Column
                        width={8}
                        textAlign={"left"}
                        className={"title-column"}
                      >
                        <Header
                          as={"h1"}
                          content={getComponentContentNodeContent({
                            components: fishermanBusinessWebsitePage.components,
                            componentId: "home_header",
                            defaultValue: "WELCOME",
                          })}
                        />
                      </Grid.Column>
                      <Grid.Column
                        width={8}
                        textAlign={"right"}
                        className={"social-media-column"}
                      >
                        <SocialMedia
                          socialMediaValues={fishermanBusiness.socialMedia}
                          buttonType={"circle"}
                          centerHeader={false}
                          colors={"white"}
                          groupButtons={false}
                          groupVertically={false}
                          inverted={true}
                          labelPosition={"free"}
                          showLabels={false}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Column width={16} className={"welcome-copy"}>
                      <Content stackable={true} textAlign={"left"}>
                        <Content.Markup
                          width={16}
                          isMarkdown={true}
                          content={getComponentContentNodeContent({
                            components: fishermanBusinessWebsitePage.components,
                            componentId: "home_description",
                          })}
                        />
                      </Content>
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <CallToActionBanner stackable={true}>
                        <CallToActionBanner.CTA>
                          <Button
                            as={OutboundLink}
                            to={externalOrderOnlinePage.url}
                            primary={true}
                            size={"large"}
                          >
                            Order Online
                          </Button>
                        </CallToActionBanner.CTA>
                      </CallToActionBanner>
                    </Grid.Column>
                  </Grid>
                </div>
              </Background.Image>
            </Background>
          }
          subfooter={false}
          className={"home-page"}
        >
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"home-content"}
          >
            <Grid.Column className={"image-gallery-column"}>
              <PaginatedGrid
                rows={3}
                className={"image-gallery"}
                mobile={{ columns: 2, rows: 4, stackable: false }}
                items={componentIterator({
                  iterator: getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "ImageCollection",
                  }),
                  component: (
                    <Image
                      background={true}
                      style={{ paddingTop: "100%", height: "100%" }}
                    />
                  ),
                  propMap: { src: "__all__" },
                })}
              />
            </Grid.Column>
            <Grid.Column className={"reviews-column"}>
              <PaginatedGrid
                rows={2}
                columns={2}
                className={"reviews"}
                tight={true}
                items={componentIterator({
                  iterator: fishermanBusiness.reviews,
                  component: <Review as={"horizontalItem"} charLimit={200} />,
                  propMap: { key: "_id", author: "author", text: "text" },
                })}
              />
              <div className={"submit-review-button-container"}>
                <CustomerReviewModal
                  businessId={fishermanBusiness._id}
                  locations={allFishermanBusinessLocation.nodes}
                  businessType={fishermanBusiness.type}
                />
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        link
        type
      }
      reviews {
        _id
        author
        text
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    externalOrderOnlinePage: fishermanBusinessWebsitePage(
      title: { eq: "Order Online" }
      pageType: { eq: "External" }
    ) {
      url
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
